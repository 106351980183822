<template>
  <div>
    <v-btn color="primary lighten-3" @click="dialog=true" class="ml-3" small>
      <v-icon class="pr-2">mdi-briefcase-plus</v-icon> NUEVA EXPERIENCIA <span v-if="$vuetify.breakpoint.smAndUp">EN EL EJERCICIO DE LA ABOGACÍA</span>
    </v-btn>
    <v-dialog v-model="dialog" max-width="700px" transition="dialog-transition">
      <v-card>
        <v-toolbar color="primary">
          <span class="subtitle white--text">{{titulo}}</span>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-form ref="formNuevaExperiencia" v-model="valid" lazy-validation>
            <Form :modelo="experiencia" :fields="fieldsExperiencia" :edit="edit" :create="true" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelarRegistro">cancelar</v-btn>
          <v-btn color="primary" @click="registrarExperiencia" :disabled="!valid" :loading="loadingSave">GUARDAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Form from "@/common/util/crud/Form";
export default {
  props: {
    areas:{
      type:Array,
      default:()=>{
        return []
      }
    },
    conDatos:{
      type:Boolean,
      default:false
    },
    urlAdd:{
      type: String,
      default: 'abogado-ciudadano/experiencia'
    },
    globalAdd:{
      type: Boolean,
      default: true
    }
  },
  components: { Form },
  data() {
    return {
      titulo: "Registrar Nueva Experiencia Laboral",
      valid: true,
      edit: true,
      dialog: false,
      experiencia: {
        areaExperiencia: null,
        institucion: null,
        tipoInstitucion: null,
        cargo: null,
        fechaInicio: null,
        fechaFin: null
      },
      fieldsExperiencia: [],
      loadingSave:false
    };
  },
  mounted() {
    this.fieldsExperiencia = [
      {
        label: "Area Experiencia",
        model: "areaExperiencia",
        rules: "requiredSelect",
        classField: "required px-1 pt-2",
        type: "autocomplete",
        items: this.conDatos?this.areas:this.$store.state.session.parametros.area_experiencia,
        class: "lg6 md6 sm6 xs12"
      },
      {
        label: "Tipo Insitucion",
        model: "tipoInstitucion",
        type: "select",
        class: "lg6 md6 sm6 xs12",
        rules: "requiredSelect",
        classField: "required px-1 pt-2",
        items: this.$store.state.session.parametros.tipo_institucion
      },
      {
        label: "Nombre Institucion",
        model: "institucion",
        type: "text",
        class: "lg12 md12 sm12 xs12",
        rules: "required",
        classField: "required px-1"
      },
      {
        label: "Cargo",
        model: "cargo",
        type: "text",
        rules: "required",
        class: "lg12 md12 sm12 xs12",
        classField: "required px-1"
      },
      {
        label: "Fecha Inicio",
        model: "fechaInicio",
        type: "date",
        birthday: true,
        rules: "required",
        class: "lg6 md6 sm6 xs6",
        classField: "required px-1"
      },
      {
        label: "Fecha Fin",
        model: "fechaFin",
        type: "date",
        birthday: true,
        min:"fechaInicio",
        class: "lg6 md6 sm6 xs6"
      }
    ];
  },
  methods: {
    registrarExperiencia() {
      if (this.$refs.formNuevaExperiencia.validate()) {
        this.loadingSave=true;
        this.$http
          .post(`${this.$apiUrl}${this.urlAdd}`, {
            experiencias: [this.experiencia]
          })
          .then(response => {
            if(this.globalAdd){
                this.$store.commit('abogado/setExperiencia',response.data.data);
                this.$emit('creado',response.data.data[0])
            }else{
                this.$emit('creado',response.data.data)
            }
            this.cancelarRegistro();
          }).catch(error=>{
            console.log(error)
            this.loadingSave=false;
          })
      }
    },
    cancelarRegistro() {
      this.loadingSave=false;
      this.$refs.formNuevaExperiencia.reset();
      this.dialog = false;
    }
  }
};
</script>

